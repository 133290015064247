import {EnvironmentType} from "@app/enums/enums";


export const environment = {
  production: false,
  apiUrl: 'https://jtemaapidev.clavisco.com/',
  name: `Tabush`,
  type: EnvironmentType.Development,
  env: 'Development',
  recatchaSiteKey: '6LcMlsIpAAAAAKBfVDv-HijjIAiyEvsmPzlcTDZE'
};
